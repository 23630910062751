<script>
  import { onMount } from 'svelte';
  import { isSupported, setup } from '@loomhq/record-sdk';
  import { oembed } from '@loomhq/loom-embed';

  const PUBLIC_APP_ID = 'public-app-id-obtained-from-developer-portal';
  const BUTTON_ID = 'loom-record-sdk-button';
  let userRole = "guest";
  let interests = [
    "Kickboxing",
    "Weightlifting",
    "Powerlifting",
    "Yoga",
    "Boxing",
    "MMA",
    "Jiu-Jitsu",
    "Judo",
    "Soccer",
    "Basketball",
    "Strongman",
    "Bodybuilding",
    "Climbing",
  ];
  let selectedInterests = new Set();

  function toggleInterest(interest) {
    if (selectedInterests.has(interest)) {
      selectedInterests.delete(interest);
    } else {
      selectedInterests.add(interest);
    }
  }

  // Dummy data for coaches
  let coaches = [
    { name: "Coach A", interests: ["Kickboxing", "Boxing", "MMA"] },
    { name: "Coach B", interests: ["Weightlifting", "Powerlifting", "Strongman"] },
    { name: "Coach C", interests: ["Yoga", "Jiu-Jitsu", "Judo"] },
  ];

  let recommendedCoach = null;

  function recommendCoach() {
    recommendedCoach = coaches.find((coach) =>
      coach.interests.some((interest) => selectedInterests.has(interest))
    );
  }

  let selectedCoach = null;

  function selectCoach(coach) {
    selectedCoach = coach;
  }

  let videoFile = null;

  function onFileChange(event) {
    videoFile = event.target.files[0];
  }

	async function sendVideo() {
	if (videoFile && selectedCoach) {
		const formData = new FormData();
		formData.append('video', videoFile);

		const response = await fetch('http://localhost:3001/upload', {
		method: 'POST',
		body: formData,
		});

		if (response.ok) {
		alert(`Video sent to ${selectedCoach.name} for feedback!`);
		} else {
		alert('Error uploading video.');
		}
	}
	}

	async function fetchCoaches() {
	const response = await fetch('http://localhost:3001/coaches');
	coaches = await response.json();
	}

	async function initLoom() {
	const { supported, error } = await isSupported();

	if (!supported) {
		console.warn(`Error setting up Loom: ${error}`);
		return;
	}

	const { configureButton } = await setup({
		publicAppId: PUBLIC_APP_ID,
	});

	const sdkButton = configureButton({ element: document.getElementById(BUTTON_ID) });

	sdkButton.on('insert-click', async (video) => {
		const { html } = await oembed(video.sharedUrl, { width: 400 });
		insertEmbedPlayer(html);
	});
	}

	onMount(() => {
	fetchCoaches();
	initLoom();
	});

	function insertEmbedPlayer(html) {
	const target = document.getElementById('target');

	if (target) {
		target.innerHTML = html;
	}
	}
</script>

<style>
  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  div {
    text-align: center;
  }
</style>

<div>
  <h2>Role</h2>
  <select bind:value="{userRole}">
    <option value="guest">Guest</option>
    <option value="admin">Admin</option>
    <option value="coach">Coach</option>
    <option value="trainer">Trainer</option>
  </select>
</div>

{#if userRole === "trainer"}
  <div>
    <h2>Interests</h2>
    <ul>
      {#each interests as interest}
        <li>
          <label>
            <input type="checkbox" on:change="{() => toggleInterest(interest)}" />
            {interest}
          </label>
        </li>
      {/each}
    </ul>
    <button on:click="{recommendCoach}">Recommend Coach</button>
  </div>
{/if}

{#if recommendedCoach}
  <div>
    <h2>Recommended Coach</h2>
    <p>{recommendedCoach.name}</p>
    <button on:click="{() => selectCoach(recommendedCoach)}">Choose Coach</button>
  </div>
{/if}

{#if selectedCoach}
  <div>
    <h2>Selected Coach: {selectedCoach.name}</h2>
    <input type="file" accept="video/*" on:change="{onFileChange}" />
    <button on:click="{sendVideo}">Send Video</button>
  </div>
{/if}

{#if userRole === "coach"}
  <div>
    <h2>Coach Area</h2>
    <p>Here, the coach would receive videos and send Loom feedback videos of the lift.</p>
  </div>
{/if}

{#if userRole === "admin"}
  <div>
    <h2>Admin Area</h2>
    <p>Here, the admin can manage users, interests, and other administrative tasks.</p>
  </div>
{/if}

{#if userRole === 'coach'}
  <div>
    <h2>Coach Area</h2>
    <button id="{BUTTON_ID}">Record</button>
    <div id="target"></div>
  </div>
{/if}